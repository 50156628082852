import type { NextPage } from 'next';
import HomeContent from 'src/Core/Layout/Home/components/HomeContent';

const Home: NextPage = () => {
  return (
    <>
      <HomeContent />
    </>
  );
};

export default Home;
