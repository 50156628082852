import { Box, Button, Grid, Typography } from '@mui/material';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import Link from 'next/link';
import theme from 'styles/theme';

const HomeContent = () => {
  const vidskiptavefurUrl = process.env.NEXT_PUBLIC_VIDSKIPTAVEFUR_URL || 'https://vidskiptavefur.motus.is';

  return (
    <>
      <Grid container direction="row" alignItems="flex-start">
        <Grid container direction="row" alignItems="center" style={{ paddingBottom: '5px' }}>
          <Link href={vidskiptavefurUrl} passHref target="_blank">
            <Button
              sx={{ textTransform: 'none', padding: 0, minWidth: 0 }}
              startIcon={<ArrowBackIcon sx={{ fontSize: 20, paddingRight: '5px' }} />}
            >
              <Typography fontSize={18}>Þjónustuvefur innheimtu</Typography>
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" mb={2}>
            Velkominn
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center"></Box>
        </Grid>
        <Grid item>
          <Box bgcolor="white" p={4} my={2}>
            <Grid container spacing={3} style={{ width: '100%' }}>
              <Grid item xs={12} md={7}>
                <Box maxWidth="1100px">
                  <Box mb={4}>
                    <Typography variant="h5" mb={2} fontWeight="bold">
                      BETA útgáfa af þjónustuvef fyrir kröfustofnun með Motus
                    </Typography>
                    <Typography variant="body2" mb={2}>
                      Þetta er BETA útgáfa af nýju sjálfsafgreiðsluviðmóti fyrir kröfur sem stofnaðar hafa verið með
                      Motus. BETA þýðir að vefurinn er enn í vinnslu og við viljum gjarnan fá að vita þú rekst á
                      eitthvað sem betur má fara eða vantar á netfangið motus@motus.is.
                    </Typography>
                    <Link href="/krofustyring/yfirlit/" passHref>
                      <Typography
                        component="a"
                        variant="body2"
                        display="flex"
                        alignItems="center"
                        fontWeight="bold"
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor: theme.palette.secondary.main,
                        }}
                      >
                        <ArrowRightAlt style={{ color: '#283C32' }} />
                        <span style={{ color: theme.palette.secondary.main }}>Yfirsýn yfir kröfur</span>
                      </Typography>
                    </Link>

                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: '4px',
                        paddingTop: '5px',
                      }}
                    >
                      <ArrowRightAlt />
                      <span style={{ color: theme.palette.secondary.main, fontWeight: 'bold' }}>Senda ábendingu á</span>
                      <a
                        href="mailto:motus@motus.is"
                        style={{
                          textDecoration: 'underline',
                          color: theme.palette.secondary.main,
                          textDecorationColor: theme.palette.secondary.main,
                          fontWeight: 'bold',
                        }}
                      >
                        motus@motus.is
                      </a>
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="h5" mb={2} fontWeight="bold">
                      Viltu vita meira um kröfustofnun Motus?
                    </Typography>
                    <Typography variant="body2" sx={{ paddingBottom: '10px' }}>
                      Nú geta viðskiptavinir okkar stofnað kröfur á þægilegan og hagkvæman hátt með Motus. Kröfurnar eru
                      tengdar við kröfupott RB og birtast greiðendum í netbanka og bókhaldskerfi. Þú stofnar kröfurnar í
                      bókhaldskerfinu og hér á þjónustuvefnum færðu greinargott yfirlit yfir stofnaðar kröfur og getur
                      breytt þeim eða fellt niður eftir þörfum.
                    </Typography>
                    <a href="https://motus.is/krofuthjonusta/krofustofnun/" target="_blank" rel="noreferrer">
                      <Typography
                        component="a"
                        variant="body2"
                        color="primary"
                        display="flex"
                        alignItems="center"
                        fontWeight="bold"
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor: theme.palette.secondary.main,
                        }}
                      >
                        <ArrowRightAlt />
                        <span style={{ color: theme.palette.secondary.main }}>Meira um kröfustofnun Motus</span>
                      </Typography>
                    </a>
                    <a href="https://motus.is/um-motus/komdu-i-vidskipti/" target="_blank" rel="noreferrer">
                      <Typography
                        variant="body2"
                        color="primary"
                        display="flex"
                        alignItems="center"
                        fontWeight="bold"
                        style={{
                          textDecoration: 'underline',
                          textDecorationColor: theme.palette.secondary.main,
                        }}
                      >
                        <ArrowRightAlt />
                        <span style={{ color: theme.palette.secondary.main }}> Viltu að við höfum samband?</span>
                      </Typography>
                    </a>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={5}>
                <Box
                  height="100%"
                  display="flex"
                  alignItems="flex-start"
                  sx={{
                    '& img': {
                      width: '100%',
                      height: 'auto',
                      maxHeight: '700px',
                      objectFit: 'contain',
                      borderRadius: '4px',
                    },
                  }}
                >
                  <img src="https://motus.is/wp-content/uploads/2024/11/grafik-scaled.jpg" alt="Nýr vefur" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HomeContent;
